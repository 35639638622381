body {
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.uppercase {
  text-transform:uppercase;
}

.header-logo {
  width:auto;
  height:40px;
  margin-left:10px;
}
.header-logo path {
  fill:#fff !important;
}

.icons-bar .MuiIcon-root {
  cursor:pointer;
}
.icons-bar .disabled svg {
  filter: grayscale(1);
  opacity:0.7;
}

.grecaptcha-badge {
  visibility: hidden;
}

.url {
  display:inline-block;
  overflow:hidden;
  vertical-align: middle;
}
.url.text {
  max-width:140px;
  text-overflow: ellipsis;
  white-space:pre;
}
.url img {
  max-width:100px;
}